import React from 'react';
import img from "../../assets/1.png";
import Slide from '../Slide/slider';
import "./MainPage.css";  
import Navbar from '../Navbar/Navbar';



const MainPage = () => {
  return (
    <> 
    <Navbar/>
         <Slide />
      <div className="container">
        <div className="imageContainer">
          <img src={img} className="imageStyle" />
        </div>
        <div className="textContainer">
          <span >WE WILL MANAGE ALL YOUR TAX AND COMPLIANCE </span><br/><br />
          We are providing all tax &amp; compliance services in India whether online or offline using the most secure technology with upfront transparent pricing approach and helping businesses to grow.<br /><br />
          A company only grows when new ideas are brought in, so new ideas can come in when sustainable management is in place. To align business goals with success, accounts must be carefully managed. When starting a new business, a full account manager should be hired to keep your business accounts accurate and secure.
        </div>
      </div>
    </>
  );
}

export default MainPage;
